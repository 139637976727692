import { Injectable, Injector } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';

@Injectable({
	providedIn: 'root'
})
export class ApplicationConfigurationsService {
	public listOfEntities: any = [];
	constructor() {
	}

	get listOfActionsForWorkflows() {
		return [{
			btn_code: 'ADD',
			btn_name: 'Add New',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}
		];
	}

	get listofStatusesForWorkflow() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'active',
			"label": "ACTIVE",
			"text": "ACTIVE",
			"bgcolor": "#03b86c"
		},
		{
			"guid": "9ea137e004825073e732a5dac6a37702",
			"value": 'inactive',
			"label": "INACTIVE",
			"text": "INACTIVE",
			"bgcolor": "#f2b80c"
		}];
	}

	get getListOfActionForAuditLogs() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get listOfActionsForCustomFieldsTab() {
		return [{
			btn_code: 'ADD',
			btn_name: 'Add New',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}];
	}

	get listOfFieldTypeForCustomFields() {
		return [{
			value: 'string',
			text: 'Text',
			visible: true,
			disabled: false,
		},
		{
			value: 'boolean',
			text: 'Boolean',
			visible: true,
			disabled: false,
		},
		{
			value: 'percentage',
			text: 'Percentage',
			visible: true,
			disabled: false,
		},
		{
			value: 'amount',
			text: 'Number',
			visible: true,
			disabled: false,
		},
		{
			value: 'date',
			text: 'Date',
			visible: true,
			disabled: false,
		}];
	}

	get listOfApprovalsForApprovalTrailLogs() {
		return [{
			value: 'pricelists',
			text: 'Price List',
			visible: true,
			disabled: false,
		},
		{
			value: 'rebatescontracts',
			text: 'Rebate Settlements',
			visible: true,
			disabled: false,
		},
		{
			value: 'minmaxenduserschargebackcontracts',
			text: 'Min-Max Chargebacks',
			visible: true,
			disabled: false,
		}];
	}

	get getListOfActionForApprovalTrailsLogs() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfConfigurationsAccordion() {
		return [
			{
				id: 'EXTERNAL_SYSTEMS',
				title: 'External Systems'
			},
			{
				id: 'Document_Templates',
				title: 'Document Templates'
			},
			{
				id: 'PRODUCT_SEARCH',
				title: 'Product Search'
			},
			{
				id: 'REBATE_AND_CHARGEBACK_SETTINGS',
				title: 'Rebate & Chargeback Settings'
			},
			{
				id: 'DISTRIBUTION_CHARGEBACK_TYPES',
				title: 'Distribution Chargeback Types'
			},
			{
				id: 'DISTRIBUTION_CHARGEBACK_STATUS',
				title: 'Distribution Chargeback Status'
			},
			{
				id: 'ORDER_CUT_OFF',
				title: 'Order Cut-Off'
			},
			{
				id: 'PAYMENT_API',
				title: 'Payment API'
			},
			{
				id: 'ASSIGN_CREDIT_CARDS_TYPES',
				title: 'Assign credit cards types'
			},
			{
				id: 'SHIPMENT_MAPPING_TYPES',
				title: 'Shipping Carrier Configuration'
			},
			{
				id: 'REBATE_CONFIGURATIONS',
				title: 'Rebates'
			},
			{
				id: 'BULK_UPDATE_DATA',
				title: 'Bulk Update Data'
			},
			{
				id: 'TERRITORY_LIST',
				title: 'Territory'
			},
			{
				id: 'CLASSIFICATION',
				title: 'Classes'
			},
			{
				id: 'ACCOUNTS',
				title: 'Accounts'
			},
			{
				id: 'COMMISSION_ON_INVOICE',
				title: 'Commission On Invoice - Commission rates and recipients are set in custom fields on the invoice'
			},
			{
				id: 'INVOICE_QUALIFICATION',
				title: 'Invoice Qualification Date'
			},
			{
				id: 'INVOICE_CUSTOM_FIELDS',
				title: 'Invoice Custom Fields'
			},
			{
				id: 'CUSTOMER_AGE_TIERS',
				title: 'Customer Age Tiers'
			},
			{
				id: 'REBATE_ON_CUSTOMER',
				title: 'Rebate Contract Dates On Customer'
			},
			{
				id: 'ENABLE_SEARCH_TRANSACTIONS',
				title: 'Order/Invoice Search'
			},
			{
				id: 'COMMISSION_ON_SALES_ORDERS',
				title: 'Commission on Sales Orders'
			},
			{
				id: 'PROFIT_ON_DOCUMENT_HEADER',
				title: 'Profit On Document Header'
			},
			{
				id: 'AR_INVOICE_LINE_ITEMS_IN_AP_INVOICE',
				title: 'A/R Invoice (or Sales Order) Line-Items in AP Invoice'
			},
			{
				id: 'EXCLUDE_SALES_PEOPLE_FROM_COST_ASSIGNMENT',
				title: 'Exclude Sales People From Cost Assignment'
			},
			{
				id: 'SALESPERSON_ENTITY_FROM_ERP',
				title: 'Salesperson Entity From ERP'
			},
			{
				id: 'TIER_BASIS',
				title: 'Tier Basis'
			},
			{
				id: 'AMORTIZED_PAYMENT',
				title: 'Amortized Payment'
			},
			{
				id: 'COST_ACCOUNTING',
				title: 'Cost Accounting'
			},
			{
				id: 'ELIGIBLE_COMMISSION',
				title: 'Eligible For Commission'
			},
			{
				id: 'SHORTPAY_NOTIFICATION',
				title: 'Shortpay Notification'
			},
			{
				id: 'VIRTUAL_INVOICES',
				title: 'Virtual Invoices'
			},
			{
				id: 'AZURE_AD_SSO',
				title: 'Single Sign-On'
			},
			{
				id: 'CHARGEBACK_LOOK_BACK_PERIOD',
				title: 'Chargeback Look Back Period'
			},
			{
				id: 'CASH_DISCOUNTS',
				title: 'Cash Discounts'
			},
			{
				id: 'PRICELIST_APPROVAL_WORKFLOW',
				title: 'Price List Approval Workflow'
			},
			{
				id: 'BUYING_GROUP_SETTINGS',
				title: "Buying Group Settings"
			},
			{
				id: 'MIN_MAX_CHARGEBACK_CLAIM',
				title: "Minimum/Maximum Claim"
			},
			{
				id: 'REBATE_SETTLEMENT_APPROVAL_WORKFLOW',
				title: "Rebate Settlement Approval Workflow"
			},
			{
				id: 'PRICE_LIST_TERMS_CONDITIONS',
				title: "Price List Terms & Conditions"
			},
			{
				id: 'SALES_REP_FOR_ORDERS',
				title: "Sales Rep For Orders"
			},
			{
				id: 'VENDOR_ENTITY_FROM_ERP',
				title: "Vendor Entity From ERP"
			},
			{
				id: 'TAX_EXEMPTS',
				title: "Tax Exempt"
			},
			{
				id: 'INCREMENTAL_PAYMENT',
				title: "Incremental Payment"
			},
			{
				id: 'DISTRIBUTION_CHANNELS',
				title: "Distribution Channels"
			},
			{
				id: 'IMPORT_END_USER_INVOICE',
				title: "Import End-User Invoice"
			},
			{
				id: 'NEGATIVE-CHARGEBACK-VALUE',
				title: "Negative Chargeback"
			},
			{
				id: 'TPM_APPROVAL_WORKFLOW',
				title: "TPM Approval Workflow"
			},
		]
	}

	get getListOfFileTypes() {
		return [
			{ text: 'Invoices', value: 'invoices' },
			{ text: 'Clients', value: 'clients' },
			{ text: 'Items', value: 'items' },
			{ text: 'Sales Persons', value: 'salesPpl' },
			{ text: 'Vendors', value: 'vendors' },
			{ text: 'AR Credit Memo', value: 'arcreditmemo' },
			{ text: 'End-User', value: 'endusers' },
			{ text: 'End-User Invoices', value: 'enduserinvoices' },
			{ text: 'AP Invoices', value: 'apinvoices' },
		];
	}

	setListOfEntities(lstEntity) {
		this.listOfEntities = lstEntity;
	}
	getListOfEntities() {
		return this.listOfEntities;
	}
}
